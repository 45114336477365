import React from "react";
import theme from "theme";
import { Theme, Text, Image, Link, LinkBox, Box, Section, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"works/ukit"} />
		<Helmet>
			<title>
				Кейс uKit | Дизайнер Артём Жигалин
			</title>
			<meta name={"description"} content={"uKit — это онлайн конструктор сайтов для бизнеса, которые можно делать своими руками."} />
			<meta property={"og:title"} content={"Кейс uKit | Дизайнер Артём Жигалин"} />
			<meta property={"og:description"} content={"uKit — это онлайн конструктор сайтов для бизнеса, которые можно делать своими руками."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-512.png?v=2022-06-20T14:58:50.557Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-32.png?v=2022-06-18T12:04:38.725Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-270.png?v=2022-06-18T12:05:00.760Z"} />
		</Helmet>
		<Box
			min-width="100px"
			border-width="22px"
			border-style="solid"
			border-color="--color-lightD2"
			display="flex"
			flex-direction="column"
			padding="12px 24px 24px 24px"
			md-border-width="0px"
		>
			<Components.Header>
				<Override slot="link" />
				<Override slot="link1" />
				<Override slot="link2" />
			</Components.Header>
			<Section quarkly-title="Cover" margin="50px 0 0px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="center"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					grid-column="2/3"
					display="flex"
					flex-direction="row"
					flex-wrap="wrap"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--headline2"
						width="100%"
						sm-font="--headline3"
					>
						uKit
					</Text>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--lead28"
						max-width="760px"
						sm-font="--lead21"
						width="100%"
					>
						uKit is an online website builder for businesses that you can make with your own hands.
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="66%"
						padding="0px 16px 0px 0px"
						sm-width="100%"
						sm-min-height="auto"
						sm-height="auto"
						sm-margin="0px 0px 18px 0px"
					>
						<LinkBox align-items="flex-start" flex-direction="row" justify-content="flex-start" margin="0px 0px 6px 0px">
							<Image
								src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08:26:23.490Z"
								display="block"
								width="24px"
								height="24px"
								margin="0px 6px 0px 0px"
								srcSet="https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08%3A26%3A23.490Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,(max-width: 1536px) 100vw,100vw"
							/>
							<Link
								href="https://ukit.com/"
								color="--primary"
								display="inline-block"
								target="_blank"
								font="--base"
								text-decoration-line="initial"
								hover-color="#0b85db"
							>
								ukit.com
							</Link>
						</LinkBox>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Completion Date: July 1, 2020
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Made in 120 days
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="33%"
						sm-width="100%"
						sm-height="auto"
						sm-min-height="auto"
					>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							UX / UI design
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Design management
						</Text>
					</Box>
				</Box>
			</Section>
			<Section quarkly-title="YoutubeVideo" margin="0 0 12px 0" inner-width="100%">
				<Components.QuarklycommunityKitYouTube url="https://youtu.be/jII90hkzKzw" width="100%" />
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase>
					GOAL
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Design and design the interface of the website builder. Make it modern and user-friendly for users without web design skills.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" sm-margin="0px 24px 16px 0px">
					MY ROLE
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					As part of the team, I was involved in the conceptualization of the product and its core value - the designer.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					As a designer, I designed and designed several key sections and features of the product: site selection in dashboard, wizard, user profile, notifications, site visit statistics, payment and domain attachment.
				</Text>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Description
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					uKit is a SaaS* website builder for small and medium-sized businesses and non-profit organizations.{" "}
				</Text>
				<Text margin="16px 0px 0px 0px" font="--base" color="--grey" grid-column="3/4">
					SaaS (Software as a service) is when programs are accessed remotely and subscribed to.
is when software is accessed remotely and on a subscription basis.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Creating starts with choosing a template, which can save a lot of time.
				</Text>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube url="https://youtu.be/rtFnYFhFJ3g" width="100%" />
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					A uKit user does not need to have web design skills and can build a website using ready-made templates, widgets and blocks.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					After selecting a template user can add edit or remove unnecessary widgets. In the designer he can also create pages and view the result.
				</Text>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube url="https://youtu.be/XecSgKu-7x4" width="100%" />
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					After publishing the site, the user can customize statistics, attach his domain, check the readiness for promotion and other actions necessary to make the site work effectively.{" "}
				</Text>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube url="https://youtu.be/Z02S2HLB_bk" width="100%" />
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Selecting a site
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					GOAL
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Design an interface to select the active site for the user. It was necessary to take into account the situations when the user has few and when there are many sites.{" "}
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					SOLUTION
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					I positioned the open window button in the header so that the site selection would be available in any section of the dashboard.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					The selection should clearly show the cards of sites. To make it easy for the user to navigate, in addition to the name, the card should contain a screenshot of the home page and the URL of the site.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Additionally, on the card, I arranged frequently used actions.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					I've formalized the various states of the site card:
				</Text>
				<List
					margin="6px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					font="--lead21"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					color="--darkL2"
				>
					<Text margin="0px 0px 6px 0px">
						selected,
					</Text>
					<Text margin="0px 0px 6px 0px">
						paid,
					</Text>
					<Text margin="0px 0px 6px 0px">
						trial expired,
					</Text>
					<Text margin="0px 0px 6px 0px">
						unpublished,
					</Text>
					<Text margin="0px 0px 6px 0px">
						blocked,
					</Text>
				</List>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					and other rare cases.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Based on user expectations, I added filtering and sorting.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					For the situation when a user has a lot of sites, I added display in the form of a list.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-projects-ru-1.png?v=2022-06-18T12:44:14.264Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						The selected site is highlighted and shows its status
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-projects-list-ru-2.png?v=2022-06-18T12:44:27.300Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						If a user has many sites, they can enable list mode for easy browsing
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-projects-on-hover-ru-3.png?v=2022-06-18T12:44:46.888Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						When you hover over a card, relevant site actions are displayed
					</Override>
					<Override slot="Image" border-width="1px" border-style="solid" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Statistics page
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					GOAL
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Users just need to connect and visually see the statistics of site visits.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Statistics should take and show data from Yandex.Metric and Google Analytics.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					Solution
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					I have highlighted the information that is most interesting to users. Then I have formalized this information in the form of visual graphs, diagrams, tables and factoids.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					I also designed a step-by-step wizard for connecting stats with supporting instructions.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-statistics-visitors-ru-1.png?v=2022-06-18T13:00:19.530Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						The main thing that the site owner wants to see is a clear dynamics of visits
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-statistics-audience-ru-2.png?v=2022-06-18T13:00:19.528Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Audience characteristics are shown in the form of pie charts
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-statistics-sources-ru-3.png?v=2022-06-18T13:00:19.531Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						The next most important thing is where the visitors came from, what search phrase, what city they came from
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-statistics-content-ru-4.png?v=2022-06-18T13:00:19.535Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Last in importance are the content characteristics and the ability to go to detailed views
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Purchase and connection of domains
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					GOAL
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Design an interface for purchasing and attaching a domain name to a website.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					The solution should be in the form of a step-by-step wizardry understandable to a novice.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					Solution
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					The start screen shows two options for action: buy or attach a domain.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					In case of purchase, the user enters the desired domain name and then the search process with the output of the results takes place. In this list users can see which domains are free and which are not, as well as the price for which they can buy the corresponding domain.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					On the attachment page, the user attaches the domain to their site in three steps.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					I have also described instructions on how to change NS records for each of the popular registrars.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-default-en-1.png?v=2022-06-19T09:48:28.523Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Choice between buying and linking a domain
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-search-en-2.png?v=2022-06-19T09:48:37.774Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Search for the right domain. The results show the most popular zones first
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-connect-step1-en-3.png?v=2022-06-19T09:48:49.086Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						The process of linking a domain to a website
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-connect-step2-en-4.png?v=2022-06-19T09:50:07.476Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						The second step of binding, specifying NS
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-connect-step3-en-5.png?v=2022-06-19T09:50:36.123Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Final step
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-connect-instructions-en-6.png?v=2022-06-19T09:50:44.215Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Instructions on how to specify NS in different domain registrars
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Payment and payment management
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					GOAL
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Design the payment interface, payment management page and payment history
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					Solution
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					On the payment screen I have placed the choice of period and payment method. Most often payment is made with a bank card, so I set it as default and made the form for entering data as similar to a bank card as possible.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					On the payment management page I have listed all active and inactive subscriptions.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					I have organized the history of payments in the form of a table where all the facts of payment are located.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-payment-default-en-1.png?v=2022-06-19T09:36:13.540Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						It's important to show period selection and payment form on the same screen to reduce the number of steps and increase conversions
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-payment-manage-en-2.png?v=2022-06-19T09:36:24.259Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Subscription Management Screen
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-payment-history-en-3.png?v=2022-06-19T09:36:32.884Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						History of invoices
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-payment-details-en-4.png?v=2022-06-19T09:36:39.715Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Invoice details
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Notification system
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					GOAL
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Need a way to notify users about bugs, end of trial or paid period, promotions, news and other events.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Need a form to send personal and general notifications.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					Solution
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					On the payment screen I have placed the choice of period and payment method. Most often payment is made with a bank card, so I set it as default and made the form for entering data as similar to a bank card as possible.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-notification-default-en-1.png?v=2022-06-18T13:02:09.498Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						The button to bring up the notification list is in the dashboard header
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-notification-toast-en-2.png?v=2022-06-18T13:02:17.066Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						A type of toast with important information for the user
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-notification-form-preview-en-4.png?v=2022-06-19T15:34:38.086Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						The form for sending notifications to users contains a preview
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-notification-form-post-en-5.png?v=2022-06-19T15:34:47.702Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						You can fine tune the send and delete date before sending it out
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-notification-all-types-en-3.png?v=2022-06-18T13:02:26.062Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Notification and toast options
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Results
				</Text>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					I was involved in designing key sections of the product: site selection, wizard, user profile, notifications, statistics, payment and domain linking.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					I made the platform user-friendly and accessible for users with no web design experience, which helped uKit strengthen its position among the best solutions for businesses.
				</Text>
			</Section>
			<Components.PrevNextButtons>
				<Override slot="text">
					Mini projects
				</Override>
				<Override slot="linkBox" href="/works/mini-projects" />
				<Override slot="text1">
					uKit Alt
				</Override>
				<Override slot="linkBox1" href="/works/ukitalt" />
				<Override slot="quarklycommunityKitMenuOverride4">
					Mini projects
				</Override>
			</Components.PrevNextButtons>
			<Components.Footer />
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"622f01726ba4ec00186af2fd"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script type={"text/javascript"} place={"endOfHead"} rawKey={"62b188e99e03ae0e7196a484"}>
				{"(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(31525948, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true\n   });"}
			</script>
		</RawHtml>
	</Theme>;
});